import './App.css';

function App() {
    return (
        <div id="page">
            <div id="page-fss" className="section-overlay" data-ambient-color="#570087" data-diffuse-color="#e000ff"></div>
            <div className="section-overlay bg-black overlay-opacity-2"></div>

            <div className="container-fluid">
                <div className="row">
                    <div id="info" className="col-md-12 text-white text-center page-info col-transform">
                        <div className="vert-middle">
                            <div className="reveal scale-out">

                                <div className="p-t-b-15">
                                    <img src="images/porkbun-logo.png" height="60" alt="" />
                                </div>

                                <div className="p-t-b-15">
                                    <h2><span className="font-weight-200">High Availability Enterprise Grade</span><br />Laravel Hosting</h2>

                                    <p>We're coming soon!<br />We're
                                        working hard to give you the best experience!<br />
                                    </p>
                                </div>
                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default App;
